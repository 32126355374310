// import React from 'react'
// import { Link } from 'react-router-dom'
// Images
import Logo from '../../../asset/logo.png'
const SideLogo = () => {
    return (
        <div className="navbar-brand-box">
        {/* <!-- Dark Logo--> */}
        <a href="#" className="logo logo-dark">
            <span className="logo-sm">
                <img src={Logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
                <img src={Logo} alt="" height="17" />
            </span>
        </a>
        {/* <!-- Light Logo--> */}
        <a href="#" className="logo logo-light">
            <span className="logo-sm">
                <img src={Logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
                <img src={Logo} alt="" height="17" />
            </span>
        </a>
        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover">
            <i className="ri-record-circle-line"></i>
        </button>
    </div>

    )
}

export default SideLogo
