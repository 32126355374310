import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import { PATH_AFTER_LOGIN } from '../config';
const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { pathname } = useLocation() <LoadingScreen />
  console.log(props, 'props')
  return (
    <Suspense fallback={''}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'user', element: <User /> },
        { path: 'user/add', element: <AddUser /> },
        { path: 'send/email', element: <Email /> },
        { path: 'send/sms', element: <SMS /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}


const Login = Loadable(lazy(() => import('../page/auth/Login')));

const Home = Loadable(lazy(() => import('../page/Home')));
const User = Loadable(lazy(() => import('../page/dashboard/user/User')));
const AddUser = Loadable(lazy(() => import('../page/dashboard/user/AddUser')));
const Email = Loadable(lazy(() => import('../page/dashboard/email/index')));
const SMS = Loadable(lazy(() => import('../page/dashboard/sms/index')));