import NotistackProvider from "./components/NotistackProvider";
import Router from "./routes";

function App() {
  return (
    <NotistackProvider>
    <Router />
    </NotistackProvider>

  );
}

export default App;
