import AccountPoper from "./AccountPoper"
const DashboardHeader = () => {
    // const mobile = isMobile;

    return (
        <div id="page-topbar">
            <div className="layout-width hrem-3">
                <div className="navbar-header">
                    <i className='mdi mdi-page-layout-sidebar-left mdi-36px'></i>
                    <div className="navbar-brand-box hrem-3">
                        {/* Dark Logo*/}
                        {/* <Link to="#" className="logo logo-dark"> */}
                            {/* <span className="logo-sm">
                                <img src={Logo} alt="" width={'100%'} />
                            </span>
                            <span className="logo-lg">
                                <img src={Logo} alt="" width={'140px'} />
                            </span> */}
                        {/* </Link> */}
                        {/* Light Logo*/}
                    </div>
                    <div className="d-flex ml-3">
                        {/* <Search /> */}
                    </div>
                    <div className="d-flex align-items-center">
                        <AccountPoper />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardHeader
