import { createContext, useEffect, useReducer } from 'react';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

type StateType = {
    isAuthenticated: boolean
    isInitialized: boolean
    user?: {} | null
}

type InitializeType = {
    type: string
    payload: {
        isAuthenticated: boolean
        user: {} | null
    }
}

type ActType = {
    type: string
    payload: {
        isAuthenticated?: boolean
        isInitialized?: boolean
        user?: {} | null
    }
}

type ActionType = InitializeType | ActType

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
}

const handlers = {
    INITIALIZE: (state: StateType, action: ActionType) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state: StateType, action: ActionType) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state: StateType) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state: StateType, action: ActionType) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
}
// @ts-ignore
const reducer = (state: StateType, action: ActionType) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    method: 'jwt',
    login: (email: string, password: string) => Promise.resolve(),
    logout: () => Promise.resolve(),
});

type AuthProviderProp = {
    children: React.ReactNode
}

function AuthProvider({ children }: AuthProviderProp) {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken) {
                    // && isValidToken(accessToken)
                    setSession(accessToken);
                    const response = await axios.get('/api/user');
                    const user = response.data;
                    // const UserResponse = await axios.post(`/api/user/profile/info/${user.id}`);
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: {},
                        },
                    });

                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: {},
                    },
                });
            }
        };
        initialize();
    }, []);

    const login = async (email: string, password: string) => {
        console.log(email, password)
        const responses = await axios.get('/testing');
        console.log(responses)
        const response = await axios.post('/api/login', {
            email,
            password
        });
        const { data, status } = response.data;
        const { token, user } = data
        setSession(token);
        if (status) {
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            });
        }
    };

    const logout = async () => {
        setSession(null);
        dispatch({ type: 'LOGOUT', payload: {} });
    };
    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'jwt',
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
