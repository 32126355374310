
import { Outlet } from 'react-router-dom';

import DashboardHeader from './header/index';
import NavBar from './navbar/index';

// import { useEffect, useState } from 'react';

const DashboardLayout = () => {


  return (
    <div id="layout-wrapper">
      <DashboardHeader />
      <NavBar />
      <Outlet />
    </div>
  )
}

export default DashboardLayout
