// import React from 'react'
// components
import SideLogo from './SideLogo'
import Navigation from './Navigation'
// // NavConfig
import navConfig from './NavConfig'

const NavBar = () => {
    return (
        <div className='app-menu navbar-menu ' >
            <SideLogo />
            <Navigation navConfig={navConfig} />
        </div>
    )

}

export default NavBar
