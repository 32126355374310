import { PATH_DASHBOARD } from "../../../routes/path";
const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
        // subheader: 'general',
        // items: [
            { title: 'User', path: PATH_DASHBOARD.user.user , icon:'mdi mdi-account-circle-outline fs-4' },
            { title: 'Email', path: PATH_DASHBOARD.user.email , icon:'mdi mdi-form-select fs-4' },
            { title: 'SMS', path: PATH_DASHBOARD.user.sms , icon:'mdi mdi-sticker-text-outline fs-4' },
         
        // ],
    // },
];

export default navConfig;

    