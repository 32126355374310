import * as Yup from 'yup';
import { Link } from "react-router-dom"
// form
import { useForm } from 'react-hook-form';
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup';

import { Alert, LoadingButton } from '@mui/lab';

import useAuth from '../../hooks/useAuth';

import { FormProvider, RHFTextField } from "../../components/hook-form"
import { useState } from 'react';

interface LoginType {
  email: string
  password: string
}
type ErrorMessage = {
  message: string
}

const Login = () => {
  const { login } = useAuth();
  const [afterSubmit, setAfterSubmit] = useState<ErrorMessage | null>(null)
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data: LoginType) => {
    try {
      await login(data.email, data.password)
    } catch (error: any) {
      setAfterSubmit(error)

      reset();
    }
    console.log(afterSubmit)
  };
  return (
    <div className="auth-page-wrapper pt-5">
      {/* auth page bg */}
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />
        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
          </svg>
        </div>
      </div>
      {/* auth page content */}
      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to={'#'} className="d-inline-block auth-logo">
                    {/* <img src="assets/images/logo-light.png" alt="" height={20} /> */}
                    RLC
                  </Link>
                </div>
                {/* <p className="mt-3 fs-15 fw-medium">Premium Admin &amp; Dashboard Template</p> */}
              </div>
            </div>
          </div>
          {/* end row */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">Sign in</p>
                  </div>
                  <div className="p-2 mt-4">
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                      {// @ts-ignore
                        !!afterSubmit && <Alert severity="error">{afterSubmit.message}</Alert>}
                      <div className="mb-3">
                        <label className="form-label">Username</label>
                        <RHFTextField name="email" type="email"
                          className="form-control pe-5"
                        />
                      </div>
                      <div className="mb-3">
                        {/* <div className="float-end">
                          <a href="auth-pass-reset-basic.html" className="text-muted">Forgot password?</a>
                        </div> */}
                        <label className="form-label" htmlFor="password-input">Password</label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <RHFTextField name="password" type="password"
                            className="form-control pe-5"
                          />
                        </div>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="auth-remember-check" />
                        <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                      </div>
                      <div className="mt-4">
                        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                          Login
                        </LoadingButton>
                      </div>

                    </FormProvider>
                  </div>
                </div>
                {/* end card body */}
              </div>
              {/* end card */}
              <div className="mt-4 text-center">
                <p className="mb-0">Don't have an account ? <a href="auth-signup-basic.html" className="fw-semibold text-primary text-decoration-underline"> Signup </a> </p>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
      {/* end auth page content */}
      {/* footer */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mb-0 text-muted">©  RLC. Crafted with <i className="mdi mdi-heart text-danger" /> by Hnh Tech</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* end Footer */}
    </div>
  )
}

export default Login
