import React from 'react'
import { NavLink } from 'react-router-dom';
interface NavigationInterface {
    title: string;
    path: string;
    icon: string;
}

type NavigationProps = {
    navConfig: NavigationInterface[]
}

const Navigation = ({ navConfig }: NavigationProps) => {

    // console.log(navConfig.map(nav => nav.title), 'Item')
    return (
        <div className="container-fluid">
            <ul className="navbar-nav" id="navbar-nav">
                {
                    navConfig.map((nav,index) =>
                        <li className="nav-item" key={index}>
                            <NavLink to={nav.path} className="nav-link menu-link" >
                                <i className={nav.icon}></i> <span data-key="t-dashboards">{nav.title}</span>
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </div>
    )
}

export default Navigation
